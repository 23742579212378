import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, ApiSeo } from './';
import { ApiImage } from './image';

export interface ApiChapter extends ApiSeo {
    id: number,
    showInMenu: boolean,
    name: string,
    menuName?: string,
    slug: string,
    content: any[],
    settings?: { [key: string]: any },
    position: number,
    bgImage: ApiImage
}

export interface ApiChapterNavigation {
    id: number,
    name: string,
    menuName?: string,
    slug: string,
    position: number,
}

const rest = {
    get: (slug: string): AxiosPromise<ApiChapter> => {
        return axios.get(`front/chapters/${slug}`);
    },
    getNavigation: (): AxiosPromise<ApiChapterNavigation[]> => {
        return axios.get(`front/chapters/navigation`);
    },
}

export default rest;
