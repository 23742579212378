import { sortBy } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import api from './api';
import { ApiChapterNavigation } from './api/chapter';
import InDepth from './modules/InDepth';
import Navigation from './modules/Navigation';
import TopBar from './modules/TopBar';
import SliderView from './views/SliderView';

const App: FC = (): JSX.Element | null => {
    const [ navigationItems, setNavigationItems ] = useState<ApiChapterNavigation[]>();

    useEffect(() => {
        api.getNavigation().then(({ data }) => {
            setNavigationItems(sortBy(data, (o) => o.position));
        });
    }, []);

    if (!navigationItems) {
        return null;
    }

    return (
        <Router>
            <Navigation items={navigationItems} />
            <TopBar />
            <Switch>
                <Route path="*">
                    <SliderView navigation={navigationItems} />
                </Route>
            </Switch>
            <InDepth />
        </Router>
    )
}

export default App;
