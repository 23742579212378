import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import api from '../api';
import { ApiChapter, ApiChapterNavigation } from '../api/chapter';
import Content from '../components/Content';
import Seo from '../components/Seo';
import SlideNavigation from '../components/SlideNavigation';
import { fitImage } from '../lib/image';

import Footer from '../modules/Footer';

interface SliderViewProps {
    navigation: ApiChapterNavigation[],
}

const SliderView: FC<SliderViewProps> = ({ navigation }): JSX.Element => {
    const [ chapter, setChapter ] = useState<ApiChapter>();
    const location = useLocation();

    useEffect(() => {
        let url = location.pathname.substring(1);
        
        if (url === '' && navigation.length > 0) {
            url = navigation[0].slug.substring(1);
        }

        api.getChapter(url).then(({ data }) => {
            setChapter(data);

            if (location.hash) {
                const anchor = document.querySelector(`span[data-anchor="${location.hash.substring(1)}"]`);
                if (anchor) {
                    const slider = document.querySelector('.slider');
                    const offset = slider?.scrollTop || 0;
                    slider?.scrollTo({ behavior: 'smooth', top: anchor.getBoundingClientRect().top - 150 + offset });
                }
            } else {
                const slider = document.querySelector('.slider');
                slider?.scrollTo({ behavior: 'smooth', top: 0 });
            }
        }).catch(() => {
            api.getChapter('404').then(({ data }) => {
                setChapter(data);
            }).catch(() => {});
        });
    }, [location]);

    return (<>
        {chapter && <Seo chapter={chapter} />}
        <div
            className={`slider ${chapter && chapter.bgImage ? 'has-background' : ''}`}
            style={chapter && chapter.bgImage && {
                backgroundImage: `url(${fitImage(chapter.bgImage.src, 1920)})`,
            }}
        >
            <section className={`slide slide--${chapter?.slug.substring(1)}`}>
                <div className="slide__inner">
                    {chapter && <Content content={chapter.content || []} settings={chapter.settings || {}} />}
                    {chapter && !chapter.bgImage && <div className="helix" style={{ backgroundImage: 'url(/images/helix.png)' }} />}
                </div>
                {chapter && chapter.showInMenu && !chapter.bgImage && <SlideNavigation
                    navigation={navigation}
                    chapter={chapter}
                />}
            </section>
            <Footer />
        </div>
    </>);
}

export default SliderView;
