import React, { createRef, FC, RefObject, useEffect, useState } from 'react';

interface AbbrProps {
    title: string,
}

const Abbr: FC<AbbrProps> = ({ children, title }): JSX.Element => {
    const abbrRef: RefObject<HTMLElement> = createRef();
    const [ description, setDescription ] = useState<string>('');
    useEffect(() => setDescription(title), [title]);

    const handleEnter = (): void => {
        if (!abbrRef.current) return;
        const rect = abbrRef.current.getBoundingClientRect();
        const div = document.createElement('div');
        div.classList.add('abbr-tooltip');
        div.innerHTML = description;
        setDescription('');
        div.style.top = `${rect.top - 15}px`;
        div.style.left = `${rect.left + 15 + rect.width}px`;
        document.body.appendChild(div);
    }

    const handleLeave = (): void => {
        const div = document.querySelector('.abbr-tooltip');
        if (!div) return;
        div.parentNode?.removeChild(div);
        setDescription(title);
    }

    return (
        <abbr
            ref={abbrRef}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            title={description}
        >
            {children}
        </abbr>
    );
}

export default Abbr;
