import React, { FC, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { ApiChapter } from '../api/chapter';

interface SeoProps {
    chapter: ApiChapter;
}

const Seo: FC<SeoProps> = ({ chapter }): JSX.Element => {
    const [ title, setTitle ] = useState<string>();
    const [ url, setUrl ] = useState<string>();
    const [ follow, setFollow ] = useState<string>('index,follow');

    useEffect(() => {
        const url = `${chapter.seoUrl || chapter.slug}`;
        setTitle(`${chapter.seoTitle || chapter.name}`);
        setUrl(`${process.env.REACT_APP_WEBSITE_URL}${/^\//.test(url) ? '' : '/'}${url}`);
        setFollow(chapter.seoNoIndex ? 'noindex,nofollow' : 'index,follow');
    }, [chapter]);

    useEffect(() => {
        if (!title || title === '') return;
        
        (window as any).dataLayer.push({
            page: {
                name: title,
                domain: 'www.testmijnkanker.nl',
                language: 'NL'
            },
            user: {
                loginStatus: '', 
                internalTraffic: false, 
                id: '' 
            },
            consent:{
                functional: '', 
                marketing: ''
            },
            event: 'page_info'
        });
    }, [title]);

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="robots" content={follow} />
            <meta name="googlebot" content={`${follow}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />
            <meta name="bingbot" content={`${follow}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={chapter.seoDescription || ''} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="TestMijnKanker" />
            {/* <meta property="article:modified_time" content="2020-10-27T13:36:20+00:00" /> */}
            {chapter.seoImage && <meta property="og:image" content={chapter.seoImage.src} />}
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    );
}

export default Seo;
