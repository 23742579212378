import parse, { HTMLReactParserOptions } from 'html-react-parser';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Abbr from './Abbr';
import { getValue, parseVimeoId } from '../lib/block';
import { fitImage } from '../lib/image';
import Accordion from './Accordion';
import Video from './Video';

const pushDataLayer = (e: any): void => {
    const url = e.target.getAttribute('href');
    if (/foundation|testmijnkanker/.test(url)) return;

    if (/\.pdf$/.test(url)) {
        const name = url.split('/');
        const ext = name[name.length-1].split('.');

        (window as any).dataLayer.push({
            event: 'download',
            events: {
                category: 'Download',
                action: name[name.length - 1],
                label: null,
                type: ext[ext.length - 1],
            }
        });
        
        return;
    }

    (window as any).dataLayer.push({
        event: 'clickout',
        events: {
            category: 'Clickout',
            action: 'External Clickout',
            label: url.replace(/http(|s)\:\/\//, '').split('/')[0],
            clickoutURL: url,
        }
    });
}

const parseOptions: HTMLReactParserOptions = {
    replace: (domNode: any) => {
        if (domNode.type !== 'tag') return;
        
        if (domNode.name === 'a') {
            let handleClick: any = undefined
            const classes = [];

            if (domNode.attribs.class) {
                classes.push(domNode.attribs.class);
                if (domNode.attribs.class.indexOf('js-open-indepth') !== -1) {
                    handleClick = (e: any) => {
                        e.preventDefault();
                        dispatchEvent(new CustomEvent('toggle-indepth'));
                    } 
                }
            }

            if (/^(mailto|http)/.test(domNode.attribs.href)) {
                return <a className={classes.join(' ')} href={domNode.attribs.href || '/'} onClick={(e)=> pushDataLayer(e)}>
                    {domNode.children[0].data}
                </a>
            }

            return <Link className={classes.join(' ')} to={domNode.attribs.href || '/'} onClick={handleClick}>
                {domNode.children[0].data}
            </Link>
        } else if (domNode.name === 'abbr') {
            return <Abbr title={domNode.attribs.title}>
                {domNode.children[0].data}
            </Abbr>
        }
    }
};

interface ContentProps {
    content: any[],
    settings?: any,
}

const Content: FC<ContentProps> = ({ content, settings }): JSX.Element => {
    const [ lightboxIndex, setLightboxIndex ] = useState<number>(0);
    const [ lightboxImages, setLightboxImages ] = useState<any[]>([]);
    
    return (<>
        <div className="content">
            {content.map((data, index) => {
                let content = null;
                const _anchor = getValue(data, '_anchor');

                if (data.block === 'text') {
                    content = parse(getValue(data, 'content') || '', parseOptions);
                } else if (data.block === 'quote') {
                    const name = getValue(data, 'name');
                    const image = getValue(data, 'image');
                    const link = getValue(data, 'link');

                    content = <a href={link || '#'} key={index} className="quote">
                        <div className="quote-content">
                            <i className="fas fa-quote-left"></i>
                            {parse(getValue(data, 'content') || '', parseOptions)}
                        </div>
                        {name && <p className="quote-name">
                            <i className="fal fa-horizontal-rule"></i>
                            {name}
                            {image && <img src={fitImage(image.src, 70, 70)} alt={name} />}
                        </p>}
                    </a>
                } else if (data.block === 'divider') {
                    content = <div key={index} className={`divider divider--${getValue(data, 'color')}`} />;
                } else if (data.block === 'accordion') {
                    content = <Accordion
                        key={index}
                        title={parse(getValue(data, 'title') || '', parseOptions)}
                        content={parse(getValue(data, 'content') || '', parseOptions)}
                    />
                } else if (data.block === 'image' || data.block === 'imagegallery') {
                    const firstImage = data.block === 'image' ? data : getValue(data, 'images')[0];
                    
                    const image = getValue(firstImage, 'image');
                    let lbox = [image.src];
                    if (data.block === 'imagegallery') {
                        lbox = getValue(data, 'images').map((o: any) => getValue(o, 'image').src);
                    }

                    const mobile = getValue(firstImage, 'mobileImage');
                    const caption = getValue(firstImage, 'caption');
                    const zoom = data.block === 'image' ? getValue(firstImage, 'zoom') : true;
                    const link = getValue(firstImage, 'link');
                    const fullImage = fitImage(image.src, 576);
                    let width = getValue(firstImage, 'width');
                    if (!width || width === '') {
                        width = '100%';
                    } else if (!width.includes('%') && !width.includes('px')) {
                        width = `${width}%`;
                    }

                    let Elem = 'div';
                    let elemProps: any = {};

                    if (zoom === true) {
                        Elem = 'a';
                        elemProps.href = image.src;
                        elemProps.onClick = (e: any) => {
                            e.preventDefault();
                            setLightboxImages(lbox);
                        }
                    } else if (link && link !== '') {
                        Elem = 'a';
                        elemProps.href = link;
                    }

                    content = (
                        <Elem
                            key={`image-${index}`}
                            className={`image ${zoom === true ? 'has-zoom' : ''}`}
                            {...elemProps}
                        >
                            <picture>
                                {mobile && (<>
                                    <source media="(max-width: 767px)" srcSet={fitImage(mobile.src, 576)} />
                                    <source media="(min-width: 768px)" srcSet={fullImage} />
                                </>)}
                                <img src={fullImage} alt={getValue(data, 'alt')} style={{ width }} />
                            </picture>
                            {caption && caption !== '' && <em className="image-caption">{caption}</em>}
                            {zoom === true && (
                                <div className="image-zoom">
                                    <i className="far fa-search-plus" />
                                </div>
                            )}
                        </Elem>
                    )
                } else if (data.block === 'imagetext') {
                    const image = getValue(data, 'image');
                    const width = getValue(data, 'width');
                    const zoom = getValue(data, 'zoom');
                    const link = getValue(data, 'link');
                    const alignment = settings && settings[data.id] ? settings[data.id].alignment : 'flex-start';

                    let Elem = 'div';
                    let elemProps: any = {};

                    if (zoom === true) {
                        Elem = 'a';
                        elemProps.href = image.src;
                        elemProps.onClick = (e: any) => {
                            e.preventDefault();
                            setLightboxImages([image.src]);
                        }
                    } else if (link && link !== '') {
                        Elem = 'a';
                        elemProps.href = link;
                    }

                    content = (
                        <div key={index} className={`text-with-image${getValue(data, 'position') === 'right' ? ' right' : ''}`} style={{ alignItems: alignment || 'flex-start' }}>
                            <div className="twi-text">
                                {parse(getValue(data, 'content') || '', parseOptions)}
                            </div>
                            <Elem
                                key={`image-${index}`}
                                className={`twi-image ${zoom === true ? 'has-zoom' : ''}`}
                                style={{ flexBasis: width }}
                                {...elemProps}
                            >
                                <img src={fitImage(image.src, 576)} alt={getValue(data, 'alt')} />
                                {zoom === true && (
                                    <div className="image-zoom" onClick={() => setLightboxImages([image.src])}>
                                        <i className="far fa-search-plus" />
                                    </div>
                                )}
                            </Elem>
                        </div>
                    );
                } else if (data.block === 'video') {
                    content = <Video key={index} vimeoId={parseVimeoId(getValue(data, 'videoId'))} />
                } else if (data.block === 'label') {
                    content = (
                        <div className="label" key={index}>
                            <div>
                                <i className="fas fa-caret-right"></i>
                                <h3>{getValue(data, 'title')}</h3>
                            </div>
                            <span>{getValue(data, 'label')}</span>
                        </div>
                    );
                } else if (data.block === 'columns') {
                    const colLeft = getValue(data, 'left');
                    const colRight = getValue(data, 'right');

                    content = (
                        <div key={index} className="columns">
                            {colLeft && <div className="ccol">
                                <Content content={colLeft} />
                            </div>}
                            {colRight && <div className="ccol">
                                <Content content={colRight} />
                            </div>}
                        </div>
                    );
                } else if (data.block === 'icon') {
                    const image = getValue(data, 'image');
                    const link = getValue(data, 'link');
                    const label = getValue(data, 'label');

                    content = (
                        <div key={index} className="icon-container">
                            <Link className="icon" to={link}>
                                <div className="slider-icon show">
                                    <div className="slider-icon__ripple"></div>
                                    <div className="slider-icon__image">
                                        <img src={fitImage(image.src, 168, 168)} alt="" />
                                    </div>
                                </div>
                                <p>{label}</p>
                            </Link>
                        </div>
                    );
                }

                if (settings && settings[data.id] && settings[data.id].bg) {
                    return (<>
                        {_anchor && _anchor !== '' && <span key={`anchor-${index}`} data-anchor={_anchor} />}
                        <div className={`bgc bgc--${settings[data.id].bg}`}>{content}</div>
                    </>);
                }

                return (<>
                    {_anchor && _anchor !== '' && <span key={`anchor-${index}`} data-anchor={_anchor} />}
                    {content}
                </>);
            })}
        </div>
        {lightboxImages.length > 0 && (
            <Lightbox
                onCloseRequest={() => {
                    setLightboxImages([]);
                    setLightboxIndex(0);
                }}
                mainSrc={lightboxImages[lightboxIndex]}
                nextSrc={lightboxImages[(lightboxIndex + 1) % lightboxImages.length]}
                prevSrc={lightboxImages[(lightboxIndex + lightboxImages.length - 1) % lightboxImages.length]}
                onMovePrevRequest={() => setLightboxIndex((lightboxIndex + lightboxImages.length - 1) % lightboxImages.length)}
                onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % lightboxImages.length)}
            />
        )}
    </>)
}

export default Content;

