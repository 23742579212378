import React, { createRef, FC, RefObject, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ApiChapterNavigation } from '../api/chapter';

interface NavigationProps {
    items: ApiChapterNavigation[];
}

const Navigation: FC<NavigationProps> = ({ items }): JSX.Element => {
    let timer: NodeJS.Timeout;
    const lineRef: RefObject<HTMLDivElement> = createRef();
    const [ activeChapter, setActiveChapter ] = useState<string>();
    const [ isTouch, setIsTouch ] = useState<boolean>(false);
    const [ open, setOpen ] = useState<boolean>(false);
    const [ badgeTop, setBadgeTop ] = useState<number>(9);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('touchstart', () => {
            document.body.classList.add('user-is-touching');
            setIsTouch(true);
        });
    }, []);

    useEffect(() => {
        setActiveChapter(location.pathname);
        setOpen(false);
    }, [location]);

    const handleMouseEnter = (e: any): void => {
        clearTimeout(timer);
        if (isTouch) return;
        setOpen(true);

        const lineTop = lineRef.current ? lineRef.current.getBoundingClientRect().top : 0;
        const badgeTop = e.currentTarget.querySelector('.navigation__chapter__badge').getBoundingClientRect().top;
        setBadgeTop(badgeTop - lineTop);
    }

    const handleMouseLeave = (): void => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setOpen(false);
        }, 750);
    }

    const handleClick = (e: any): void => {
        if (!isTouch || open) return;
        e.preventDefault();
        clearTimeout(timer);
        setOpen(true);
    }

    return (
        <div
            className={[
                'navigation',
                open ? 'navigation--open' : '',
            ].join(' ')}
        >
            <div
                className="navigation__handler"
                onClick={() => setOpen(!open)}
            >
                <i className="fal fa-bars"></i>
                <i className="fal fa-times"></i>
                <span>Menu</span>
            </div>

            <div className="navigation__chapters">
                <div
                    className="navigation__chapters__line"
                    ref={lineRef}
                />
                <div
                    className="active-badge"
                    style={{ top: badgeTop }}
                />
                
                {items.map((item) => (
                    <Link
                        key={`nav-${item.id}`}
                        className={[
                            'navigation__chapter',
                            activeChapter === item.slug ? 'is-active' : '',
                        ].join(' ')}
                        onClick={handleClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        to={item.slug}
                    >
                        <span className="navigation__chapter__badge"></span>
                        <span className="navigation__chapter__label">{item.menuName || item.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Navigation;
