import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const TopBar: FC = (): JSX.Element => {
    return (
        <div className="top-bar">
            <div>
                <Link
                    className="logo"
                    to="/"
                >
                    <img alt="Roche" src="/images/foundation-medicine.svg" />
                    <span></span>
                    <img alt="Roche" src="/images/roche-logo.svg" />
                </Link>
                {/* <a
                    className="button"
                    href="https://www.foundationmedicine.nl"
                    target="_blank"
                    rel="noopener noreferer"
                >
                    Naar de site voor healthcare professionals
                </a> */}
            </div>
        </div>
    );
}

export default TopBar;
