import { title } from 'node:process';
import React, { createRef, FC, RefObject, useState } from 'react';
import styles from './styles.module.scss';

interface AccordionProps {
    title: any,
    content: any,
}

const Accordion: FC<AccordionProps> = ({ title, content }): JSX.Element => {
    const containerRef: RefObject<HTMLDivElement> = createRef();
    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(false);

    const handleToggle = (): void => {
        if (!containerRef.current) return;
        containerRef.current.style.setProperty('--max-height', containerRef.current.scrollHeight + 'px');
        setIsCollapsed(!isCollapsed)
    }
    
    return (
        <div className={`${styles.container} ${isCollapsed ? styles.collapsed : ''}`}>
            <p className={styles.title} onClick={() => handleToggle()}>
                <i className={`fas fa-caret-${isCollapsed ? 'down' : 'right'}`}></i>
                {title}
            </p>
            <div className={styles.content} ref={containerRef}>
                {content}
            </div>
        </div>
    );
}

export default Accordion;
