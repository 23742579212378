import { findIndex } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiChapter, ApiChapterNavigation } from '../api/chapter';

interface SlideNavigationProps {
    chapter: ApiChapter,
    navigation: ApiChapterNavigation[],
}

const SlideNavigation: FC<SlideNavigationProps> = ({ chapter, navigation }): JSX.Element => {
    const [ next, setNext ] = useState<ApiChapterNavigation>();
    const [ prev, setPrev ] = useState<ApiChapterNavigation>();
    
    useEffect(() => {
        const index = findIndex(navigation, { position: chapter.position });
        if (index === undefined) return;
        setNext(navigation[index + 1]);
        setPrev(navigation[index - 1]);
    }, [chapter]);

    return (
        <div className="slider__navigation">
            <div>
                {prev && (
                    <Link to={prev.slug}>
                        <img src="/images/arrow-left.svg" alt="" />
                        {prev.name}
                    </Link>
                )}
            </div>
            <div>
                {next && (
                    <Link className="next" to={next.slug}>
                        {next.name}
                        <img src="/images/arrow-right.svg" alt="" />
                    </Link>
                )}
            </div>
        </div>
    );
}

export default SlideNavigation;
