import axios from 'axios';
import chapter from './chapter';
import { ApiImage } from './image';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiSeo {
    seoTitle: string,
    seoDescription: string,
    seoUrl: string,
    seoImageId?: number,
    seoImage?: ApiImage,
    seoNoIndex: boolean,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getChapter: chapter.get,
    getNavigation: chapter.getNavigation,
};

export default api;
