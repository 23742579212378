export const fitImage = (src: string, width: number, height?: number): string => {
    const max = window.innerWidth < width ? window.innerWidth : 1920;

    if (width > max) {
        width = max;
        if (height) {
            height = height * (max / width);
        }
    }

    let dimensions = `${width}${height ? `x${height}` : ''}`;
    return src.replace('images/', `images/${dimensions}/`);
}
