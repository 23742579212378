import { find } from 'lodash';

export const getValue = (data: any, id: string): any => {
    const field = find(data.fields, { id });
    return field ? field.value : undefined;
}

export const parseVimeoId = (url: string) => {
    const split = url.split('?')[0].replace(/\/$/, '').split('/');
    return split[split.length - 1];
}
