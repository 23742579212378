import React, { FC, useEffect, useState } from 'react';
import api from '../api';
import { ApiChapter } from '../api/chapter';
import Content from '../components/Content';

const InDepth: FC = (): JSX.Element => {
    const [ chapter, setChapter ] = useState<ApiChapter>();
    const [ open, setOpen ] = useState<boolean>(false);

    useEffect(() => {
        if (!chapter && open) {
            api.getChapter(process.env.REACT_APP_INDEPTH || '').then(({ data }) => setChapter(data));
        }
    }, [open, chapter]);

    useEffect(() => {
        window.addEventListener('toggle-indepth', () => setOpen(true), true);
    }, []);

    return (
        <div
            className={[
                'popout',
                open ? 'popout--out' : '',
            ].join(' ')}
        >
            <div className="popout__overlay" onClick={() => setOpen(false)}></div>
            <div className="inner">
                <div className="popout__close" onClick={() => setOpen(false)}>
                    <i className="fa fa-times"></i>
                    Sluiten
                </div>
                {chapter && <Content content={chapter.content || []} />}
            </div>
        </div>
    );
}

export default InDepth;
