import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Footer: FC = (): JSX.Element => {
    return (
        <div className="footer">
            <div className="content">
                <p>
                    <a href="https://www.roche.nl/nl/legal-statement.html" target="_blank" rel="noopener noreferer">
                        LEGAL STATEMENT
                    </a> | <a href="https://www.roche.nl/nl/privacy-policy.html" target="_blank" rel="noopener noreferer">
                        PRIVACY STATEMENT
                    </a> | <a className="ot-sdk-show-settings">Cookies</a> | M-NL-00000800 | M-NL-00001133
                </p>
                <p>
                    FoundationOne<sup>&reg;</sup>CDx, FoundationOne®Liquid CDx en FoundationOne®Heme zijn services van Foundation Medicine Inc. Roche is exclusief bevoegd om de uitgebreide DNA testen in Nederland op de markt te brengen. De analyses worden uitgevoerd door Foundation Medicine. De rapporten die u ontvangt, kunnen informatie bevatten over geneesmiddelen of behandelingen die (nog) niet in Nederland zijn geregistreerd of beschikbaar zijn. De rapporten zijn bedoeld om u volledige en wetenschappelijke informatie te verstrekken. Het is absoluut niet de bedoeling van Roche om het gebruik van niet-geregistreerde geneesmiddelen en/ of behandelingen aan te moedigen.
                </p>
            </div>
        </div>
    );
}

export default Footer;
