import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';

interface VideoProps {
    vimeoId: string,
}

const Video: FC<VideoProps> = ({ vimeoId }): JSX.Element => {
    const vimeoRef: RefObject<HTMLIFrameElement> = createRef();

    const handleVimeoApi = useCallback(() => {
        if (!vimeoRef.current) return;
        const player = new (window as any).Vimeo.Player(vimeoRef.current);
        let videoTitle: string = '';
        let videoKey: any[] = [];
        let videoStart: boolean = false;

        player.on('play', () => {
            if (videoStart || !vimeoRef.current) return;
            videoStart = true;

            (window as any).dataLayer.push({
                event: 'video',
                events: {
                    category: 'Video',
                    action: 'Start',
                    label: videoTitle,
                },
                video: {
                    duration: 0,
                    percentage: 0,
                    url: vimeoRef.current.src,
                }
            });
        });

        const videoTrack = (e: any): void => {
            if (!vimeoRef.current) return;
            let push = 0;

            if (e.percent >= .25 && videoKey.indexOf(25) === -1) {
                videoKey.push(25);
                push = 25;
            } else if (e.percent >= .5 && videoKey.indexOf(50) === -1) {
                videoKey.push(50);
                push = 50;
            }  else if (e.percent >= .75 && videoKey.indexOf(75) === -1) {
                videoKey.push(75);
                push = 75;
            } else if (e.percent >= 1 && videoKey.indexOf(100) === -1) {
                videoKey.push(100);
                push = 100;
            } 

            if (push) {
                (window as any).dataLayer.push({
                    event: 'video',
                    events: {
                        category: 'Video',
                        action: 'Duration',
                        label: videoTitle,
                    },
                    video: {
                        duration: e.duration,
                        percentage: `${push}%`,
                        url: vimeoRef.current.src,
                    }
                });
            }
        }

        player.on('timeupdate', videoTrack);
        player.on('ended', videoTrack);
        player.getVideoTitle().then((title: string) => {
            videoTitle = title;
        });
    }, [vimeoRef]);

    useEffect(() => {
        handleVimeoApi();
    }, [handleVimeoApi]);

    return (
        <div style={{ paddingBottom: '56.25%', position: 'relative', marginBottom: 40 }}>
            <iframe
                allow="autoplay; fullscreen; picture-in-picture"
                src={`https://player.vimeo.com/video/${vimeoId}`}
                style={{ width: '100%', border: 0, height: '100%', position: 'absolute' }}
                title="Vimeo Video"
                ref={vimeoRef}
            />
        </div>
    )
}

export default Video;
